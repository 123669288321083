var formatNumber = function (number) {
  return parseFloat(number).toFixed(2).replace(/\./g, ",");
};
export const getCSPPrice = function (cspPrices, productId) {
  var cspPrice = "";

  if (cspPrices[productId] != undefined) {
    /**
     * On product card, CSP plugin displays price (simple product) or price range (variable product)
     * according to the min quantity (1) price.
     * Thus, here we are doing the same by accessing the key 1
     * On the retreived cspPrices[productId] object, its key represents the min quantity and
     * value represents the price for that min quantity
     */

    // Simple product
    if (cspPrices[productId]["1"] != undefined) {
      cspPrice = formatNumber(cspPrices[productId]["1"]);

      // For variable product, dig deeper
    } else {
      var priceRange = [];

      for (let [key, value] of Object.entries(cspPrices[productId])) {
        priceRange.push(value["1"]);
      }

      var lowestPrice = formatNumber(Math.min(...priceRange));
      var highestPrice = formatNumber(Math.max(...priceRange));

      if (lowestPrice == highestPrice) {
        cspPrice = lowestPrice;
      } else {
        cspPrice = lowestPrice + " € – " + highestPrice;
      }
    }
  } else {
    cspPrice = false;
  }

  return cspPrice;
};