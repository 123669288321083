const algoliasearch = require('algoliasearch/lite');
import { autocomplete, getAlgoliaResults } from '@algolia/autocomplete-js'
import { getCSPPrice } from './helpers/getCSPPrice';

const table_prefix = algolia_search_object.table_prefix;
const indexName = table_prefix + 'product';
const searchClient = algoliasearch(algolia_search_object.algolia_id_and_key['application_id'], algolia_search_object.algolia_id_and_key['search_only_api_key']);

const isLoggedIn = algolia_search_object.is_logged_in;

// Get prices from CSP plugin. Set to false if doesn't receive one
const cspPrices = algolia_search_object.csp_prices
  ? algolia_search_object.csp_prices
  : false;
  
autocomplete({
    container: '#autocomplete',
    placeholder: 'Etsi tuotteita',
    // autoFocus: true,
    detachedMediaQuery: '',
    debug: false,
    translations: {
        detachedCancelButtonText: ''
    },
    getSources({ query }) {
      return [
        {
          sourceId: 'products',
          getItems() {
            return getAlgoliaResults({
              searchClient,
              queries: [
                {
                  indexName: indexName,
                  query,
                  params: {
                    hitsPerPage: 30,
                    // attributesToSnippet: ['name:10'],
                    attributesToSnippet: ['name:20', 'content:25'],
                    snippetEllipsisText: '…',
                  },
                },
              ],
            });
          },
          getItemUrl({ item }) {
            return item.url;
          },
          templates: {
            item({ item, components, html }) {
              return html`
            <a class="aa-link" href="${item.url}">
              <div class="aa-ItemWrapper">
                <div class="aa-ItemContent">
                  <div class="aa-ItemIcon aa-ItemIcon--alignTop">
                    <div
                      class="aa-thumbnail"
                      style="background-image:url(${item.images.medium ? item.images.medium.url : '/wp-content/plugins/wv-algolia/img/thumbnail-placeholder.jpeg'})">
                    </div>
                  </div>
                  <div class="aa-ItemContentBody">
                    <div class="aa-ItemContentTitle">
                      ${components.Highlight({
                        hit: item,
                        attribute: 'title',
                      })}
                    </div>
                    <div class="aa-ItemContentDescription">
                      ${components.Snippet({
                        hit: item,
                        attribute: 'content',
                      })}
                    </div>
                    ${isLoggedIn ? 
                      (
                        getCSPPrice(cspPrices, item.product_id) ? 
                        html`<div class="price"><span class="regular-price">${getCSPPrice(cspPrices, item.product_id)} €</span></div>` : 
                        (item.price ? 
                          (
                            html`<div class="price">
                              ${item.on_sale ? 
                              html`<span class="regular-price line-through">${item.regular_price_formatted} €</span><span class="sale-price">${ item.sale_price_formatted } €</span>` : 
                              html`<span class="regular-price">${item.regular_price_formatted} €</span>`}
                            </div>`
                          ) :
                          ''
                        )
                      ) : 
                      ''
                    }
                  </div>
                </div>
              </div>
            </a>`;
            },
            footer: function({ state, html }) {
              return html`
              <a href="/tuotteet/?query=${state.query}">Siirry koko hakutulossivulle</a>
              `;
            }
          },
        },
      ];
    },
    onSubmit({ state }) {
        window.location = "/tuotteet/?query=" + state.query;
    },
  });